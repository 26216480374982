<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'Meetings' })"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Meetings
          </v-btn>
          <h2 class="darkGrey--text d-flex flex-column custom-section-title">
            <span>Minutes of <span class="font-weight-black">Meeting Name</span></span>
            <p class="text-body-1">for Board Name</p>
          </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Board Members
            </span>
          </v-tooltip>
          <v-chip class="ml-6">Published</v-chip>
        </span>

        <v-spacer></v-spacer>

        <span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-bind="attrs"
                v-on="on"
                class="mr-3"
              >
                <v-icon size="32">mdi-download</v-icon>
              </v-btn>
            </template>
            <span>
              Download Minutes
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-bind="attrs"
                v-on="on"
                class="mr-3"
                @click="openModal('revertOriginal')"
              >
                <v-icon size="32">mdi-rotate-left</v-icon>
              </v-btn>
            </template>
            <span>
              Revert to Original
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-bind="attrs"
                v-on="on"
                class="mr-8"
                @click="openModal('finalize')"
              >
                <v-icon size="32">mdi-stop-circle</v-icon>
              </v-btn>
            </template>
            <span>
              Finalize Minutes
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                fab
                width="48"
                height="48"
                color="secondary"
                v-bind="attrs"
                v-on="on"
                @click="openModal('publish')"
              >
                <v-icon size="24">mdi-play</v-icon>
              </v-btn>
            </template>
            <span>
              Publish to Uploads
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                fab
                width="48"
                height="48"
                color="important"
                class="white--text"
                v-bind="attrs"
                v-on="on"
                @click="openModal('unpublish')"
              >
                <v-icon size="24">mdi-pause</v-icon>
              </v-btn>
            </template>
            <span>
              Unpublish to Uploads
            </span>
          </v-tooltip>
        </span>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card
        >
          <v-data-table
            :headers="headers"
            hide-default-header
            hide-default-footer
            :items="minutesSample"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
          >
            <template v-slot:item.sortable>
              <v-icon color="lightGrey">
                mdi-drag-horizontal-variant
              </v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              <span v-if="item.type === 'region'" class="font-weight-bold d-flex text-uppercase py-6">
                {{ item.name }}
              </span>
              <span v-if="item.type === 'item'" class="d-flex text-caption py-4">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:item.actions>
              <div class="d-flex flex-row justify-end">

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenu"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          color="darkGrey"
          elevation="0"
          class="py-14 d-flex justify-center"
        >
          <v-card
            color="white"
            tile
            style="width: 4.25in; height: 5.5in"
          >
            &nbsp;
          </v-card>
        </v-card>
      </v-col>
    </v-row>

    <meeting-minutes-edit-original v-model="modals.editOriginal"></meeting-minutes-edit-original>
    <meeting-minutes-action-item v-model="modals.addAction"></meeting-minutes-action-item>
    <meeting-minutes-text v-model="modals.addText"></meeting-minutes-text>
    <meeting-minutes-vote v-model="modals.addVote"></meeting-minutes-vote>
    <meeting-minutes-delete v-model="modals.delete"></meeting-minutes-delete>
    <meeting-minutes-finalize v-model="modals.finalize"></meeting-minutes-finalize>
    <meeting-minutes-revert-original v-model="modals.revertOriginal"></meeting-minutes-revert-original>
    <meeting-minutes-publish v-model="modals.publish"></meeting-minutes-publish>
    <meeting-minutes-unpublish v-model="modals.unpublish"></meeting-minutes-unpublish>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import MeetingMinutesEditOriginal from '@/components/Meetings/MeetingMinutesEditOriginal'
import MeetingMinutesActionItem from '@/components/Meetings/MeetingMinutesActionItem'
import MeetingMinutesText from '@/components/Meetings/MeetingMinutesText'
import MeetingMinutesVote from '@/components/Meetings/MeetingMinutesVote'
import MeetingMinutesDelete from '@/components/Meetings/MeetingMinutesDelete'
import MeetingMinutesFinalize from '@/components/Meetings/MeetingMinutesFinalize'
import MeetingMinutesRevertOriginal from '@/components/Meetings/MeetingMinutesRevertOriginal'
import MeetingMinutesPublish from '@/components/Meetings/MeetingMinutesPublish'
import MeetingMinutesUnpublish from '@/components/Meetings/MeetingMinutesUnpublish'

export default Vue.extend({
  name: 'MeetingMinutes',
  components: {
    Portal,
    MeetingMinutesEditOriginal,
    MeetingMinutesActionItem,
    MeetingMinutesText,
    MeetingMinutesVote,
    MeetingMinutesDelete,
    MeetingMinutesFinalize,
    MeetingMinutesRevertOriginal,
    MeetingMinutesPublish,
    MeetingMinutesUnpublish
  },
  data: () => ({
    modeType: '',
    modals: {
      editOriginal: false,
      addText: false,
      addAction: false,
      addVote: false,
      delete: false,
      finalize: false,
      revertOriginal: false,
      publish: false,
      unpublish: false
    },
    breadcrumbsItems: [
      {
        text: 'Meetings',
        disabled: false,
        href: ''
      },
      {
        text: 'Minutes of Meeting Name',
        disabled: true,
        href: '/boards'
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit Original', actions: 'editOriginal' },
      { icon: 'mdi-cursor-text', text: 'Add Text Below', actions: 'addText' },
      { icon: 'mdi-clipboard-text', text: 'Add Action Item', actions: 'addAction' },
      { icon: 'mdi-vote', text: 'Add Vote', actions: 'addVote' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: '', value: 'sortable' },
      { text: 'Label', value: 'name' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ],
    minutesSample: [
      { name: 'Name of First Region', type: 'region' },
      { name: 'First Item Title in the second region', type: 'item' },
      { name: 'Second Item Title in the second region', type: 'item' },
      { name: 'Third Item Title in the second region', type: 'item' },
      { name: 'Name of Second Region', type: 'region' },
      { name: 'Name of Third Region', type: 'region' },
      { name: 'First Item Title in the Third region', type: 'item' },
      { name: 'Second Item Title in the Third region', type: 'item' },
      { name: 'Third Item Title in the Third region', type: 'item' }
    ]
  }),
  methods: {
    openModal (item, optional) {
      if (optional) this.modeType = optional
      this.modals[item] = true
    }
  }
})
</script>
