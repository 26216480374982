<template>

  <v-dialog
    width="1000"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Add Vote Format to region of Minutes from</span>
        <span><span class="font-weight-black">Meeting Name</span>for <span class="font-weight-black">Board Name</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>

            <v-tabs
              v-model="tab"
              color="secondary"
              centered
              class="custom-tab"
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-1">
                Vote
              </v-tab>

              <v-tab href="#tab-2">
                Format
              </v-tab>

            </v-tabs>

            <v-tabs-items v-model="tab">

              <v-tab-item value="tab-1">
                <v-card flat>
                  <v-card-text>

                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">
                            Board Members
                          </th>
                         <th class="text-left text-center" v-for="(item,n) in actionLabels" :key="n">
                            {{ item.name }}
                          </th>
                          <th class="text-left">
                            Other
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(item,i) in namesSample"
                          :key="i"
                        >
                          <td>{{ item }}</td>
                          <td class="text-center" v-for="(item,n) in actionLabels" :key="n">
                            <input
                              type="radio"
                              :value="'vote'+n"
                              v-model="voting[i]"
                            >
                          </td>
                          <td :class="voting[i]==='vote-other'? '' : 'text-center'">
                            <span class="d-flex align-center py-4">
                              <input
                                type="radio"
                                :value="'vote-other'"
                                v-model="voting[i]"
                              >
                              <v-text-field
                                class="d-flex ml-4"
                                outlined
                                hide-details
                                dense
                                v-if="voting[i]==='vote-other'"
                              ></v-text-field>
                            </span>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item value="tab-2">
                <v-card flat>
                  <v-card-text>

                    <v-row>
                      <v-col cols="7">

                        <v-select
                          v-model="voteFormatSelected"
                          :items="voteFormat"
                          outlined
                          hide-details
                          label="Format"
                          class="mb-8"
                        ></v-select>

                        <v-card elevation="0" color="veryLightGrey">
                          <v-card-text>

                            <span v-if="viewCount && !viewName" class="pa-4 mt-2 d-flex">
                              <span
                                v-for="(item,n) in actionLabels"
                                :key="n"
                                class="darkGrey--text text-lg-body-1"
                              >
                                {{ nameCountSample[n].count }} <span class="font-weight-bold">{{ item.name }}</span><span v-if="n != Object.keys(actionLabels).length - 1">,</span>
                              </span>
                            </span>

                            <v-simple-table
                              class="transparent"
                              v-if="!viewCount && viewName || viewCount && viewName"
                            >
                              <template v-slot:default>
                                <tbody>
                                <tr
                                  v-for="(item,n) in actionLabels"
                                  :key="n"
                                >
                                  <td width="30%">
                                    <span class="font-weight-bold">{{ item.name }}</span>
                                  </td>
                                  <td v-if="viewCount" :width="viewName ? '15%' : '70%'">
                                    <v-chip>{{ nameCountSample[n].count }}</v-chip>
                                  </td>
                                  <td v-if="viewName" :width="viewCount ? '55%' : '70%'">
                                    <span class="py-4 d-flex">
                                      {{ nameCountSample[n].names }}
                                    </span>
                                  </td>
                                </tr>
                                </tbody>
                              </template>
                            </v-simple-table>

                            <span v-if="includeResult===true" class="font-weight-bold pa-4 mt-4 d-flex darkGrey--text text-body-1">
                              <span v-if="includeResultSelected!=='custom'">
                                {{ includeResultSelected }}
                              </span>
                              <span v-if="includeResultSelected==='custom'">
                                {{ includeResultCustom }}
                              </span>
                            </span>

                          </v-card-text>
                        </v-card>

                        <div class="d-flex px-6 align-center">
                          <span><v-icon color="orange lighten-2">mdi-alert</v-icon></span>
                          <span>Test only (DEVELOPMENT):</span>
                          <v-checkbox v-model="viewName" label="Name" class="d-flex px-4" :disabled="viewCount===false"></v-checkbox>
                          <v-checkbox v-model="viewCount" label="Count" :disabled="viewName===false"></v-checkbox>
                        </div>

                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4">

                        <v-switch
                          v-model="includeResult"
                          hide-details
                          color="secondary"
                          inset
                          label="Include result"
                          class="pa-0 ma-0 py-4"
                        ></v-switch>

                        <span v-if="includeResult">
                          <v-radio-group v-model="includeResultSelected">
                            <v-radio
                              v-for="(item,n) in resultTexts"
                              :key="n"
                              :label="item.name"
                              :value="item.name"
                              color="secondary"
                            ></v-radio>
                            <v-radio
                              label="Custom"
                              value="custom"
                              color="secondary"
                            ></v-radio>
                          </v-radio-group>

                          <v-text-field
                            v-if="includeResultSelected==='custom'"
                            outlined
                            placeholder="Custom result text"
                            hide-details
                            v-model="includeResultCustom"
                            dense
                          ></v-text-field>
                        </span>

                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-tab-item>

            </v-tabs-items>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingMinutesVote',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    tab: 1,
    includeResult: false,
    includeResultSelected: '',
    includeResultCustom: '',
    resultText: '',
    viewName: true,
    viewCount: true,
    voting: [],
    actionLabels: [
      { name: 'Yes' },
      { name: 'No' },
      { name: 'Abstain' },
      { name: 'Absent' }
    ],
    resultTexts: [
      { name: 'Motion Carries', active: false },
      { name: 'Motion Fails', active: false }
    ],
    namesSample: [
      'Charles Simon',
      'Rico Reis',
      'Vee Caron',
      'Paul Smith',
      'Chad Edwards',
      'Jason Lewis',
      'Marcos Gonzalez',
      'David Johnson',
      'Joseph Miller',
      'Peter White'
    ],
    voteFormat: [
      'Standard',
      'City Council Vote',
      'Simple Vote Format',
      'Yes and No Only'
    ],
    voteFormatSelected: 'Standard',
    nameCountSample: [
      { names: 'Simon, Reis, Caron, Carter, Smith, Brown', count: 6 },
      { names: 'Edwards, Leroux', count: 2 },
      { names: 'Miller', count: 1 },
      { names: 'Davis, Johnson', count: 2 },
      { names: 'Garcia, Jones, Rodriguez', count: 3 },
      { names: 'Lopez, Thompson, Lee, Taylor', count: 4 },
      { names: '', count: 0 },
      { names: 'Gonzalez, White, Lewis', count: 3 },
      { names: 'Anderson, Silva, King', count: 3 },
      { names: 'Hill', count: 1 }
    ]
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
