<template>

  <v-dialog
    width="750"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Add Action Item to region of Minutes</span>
        <span>of <span class="font-weight-black">Meeting Name</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>

            <v-row>
              <v-col>
                <v-select
                  v-model="usersSequence"
                  :items="users"
                  small-chips
                  deletable-chips
                  outlined
                  multiple
                  hide-details
                  label="Staff"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  hide-details
                  label="Content"
                ></v-textarea>
              </v-col>
            </v-row>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary">Add</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingMinutesActionItem',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    users: [
      'Rico Reis',
      'Vee Caron',
      'Charles Simon',
      'Tony Cappozi',
      'Raph Leroux',
      'Kyler Miles',
      'Stephen Cawley',
      'Arthur da Silva',
      'David Sanchez',
      'Michael Jordan',
      'Pixie Howells',
      'Tayyibah Pratt',
      'Eleri Mccoy',
      'Ishan Hogg',
      'Krishan Vickers',
      'Nayan Wilkinson',
      'Shabaz Bonilla',
      'Franciszek Reed',
      'Finn Rodrigues',
      'Maxine Huff',
      'Tiarna Carter',
      'Chanel Molina',
      'Emerson Kline',
      'Elsie-Mae Shea',
      'Esha Chun',
      'Ishaan Samuels',
      'Avleen Calhoun',
      'Lewie Hale',
      'Cerys Landry',
      'Tayler Horner'
    ],
    usersSequence: [
      'Vee Caron',
      'Charles Simon'
    ]
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
